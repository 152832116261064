<template>
  <div style="background-color:#f0f1f5;overflow:hidden;" class="pc-only" v-if="!isMobile">
    <div class="swiper-container" id="home-swiper">
      <div class="swiper-wrapper">
        <div class="card1 swiper-slide">
          <h2>국내 최초에서 국내 최고의 해외문자 사이트</h2>
          <p>
            런던문자는 그동안 축적된 풍부한 경험과 전문 기술을 기반으로<br />
            주식 금융, 스포츠, 엔터테인먼트, 게임, 4차산업 코인 AI 등<br />
            다양한 산업에서 기업들이 필요로 하는 플랫폼을 제공하기 위해<br />
            지속적으로 노력하고 있습니다.
          </p>
        </div>
        <div class="swiper-slide card2">
          <h2>해외발신 번호 사용</h2>
          <p>
            런던을 비롯한 해외의 발신번호를<br />
            자동 채택하여 문자를 발송하기 때문에<br />
            복잡한 발신번호 관리가 필요 없습니다.
          </p>
        </div>
        <div class="swiper-slide card3">
          <h2>2배 길어진 문자</h2>
          <p>
            해외문자는 일반 단문 문자보다 약 2배 정도<br />
            길게 문자 작성이 가능합니다.<br />
            (한글 70자, 영문 140자까지)
          </p>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
export default {
  head: {
    link: [
      { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.css' },
    ],
    script: [
      { type: 'text/javascript', src: 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.js', async: false },
    ],
  },
  created: function () {
    // this.$on('okHead', function () {
    // });
  },
  mounted: function () {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      if (!window.Swiper) {
        const t = this
        setTimeout(function () {
          t.initSwiper()
        }, 500)
        return
      }

      new window.Swiper('#home-swiper', {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        // mousewheel: {
        //   invert: false,
        // },
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
        },
      })
    }
  }
}

</script>
<style scoped>
.file-select>.select-button {
  padding: 1rem;

  color: white;
  background-color: #2EA169;

  border-radius: .3rem;

  text-align: center;
  font-weight: bold;
}

/* Don't forget to hide the original file input! */
.file-select>input[type="file"] {
  display: none;
}
</style>
