<template>
  <div class="animated fadeIn" id="home-page">
    <div id="home-video-container">
      <video ref="homeVideo" @timeupdate="updateVideoTime" autoplay playsinline muted loop :src="videoSrc"></video>
      <div class="bg_line"></div>
      <img src="/img/logo_video.webp" id="home-img" v-bind:class="{ show: !showText && index === -1 }"
        alt="런던문자 비디오 로고 이미지" />
      <h1 class="home-text" v-bind:class="{ show: showText && index === 0 }">
        런던문자는<br />
        <span>국내 최초</span><br />
        해외문자 발송 사이트입니다.
      </h1>
      <div class="home-text" v-bind:class="{ show: showText && index === 1 }">
        런던문자는<br />
        <span>신속한</span><br />
        국제문자 발송 사이트입니다.
      </div>
      <div class="home-text" v-bind:class="{ show: showText && index === 2 }">
        런던문자는<br />
        <span>정직한</span><br />
        해외문자 발송 사이트입니다.
      </div>
      <div class="home-text" v-bind:class="{ show: showText && index === 3 }">
        런던문자는<br />
        <span>안전한</span><br />
        국제문자 발송 사이트입니다.
      </div>
    </div>
    <HomeDesktopSwiper v-if="!isMobile"></HomeDesktopSwiper>
    <div class="mo-only mo-card-section" v-else>
      <div>
        <img src="/img/bg_card_1.webp" alt="런던문자 슬라이드 이미지1" width="640" height="290">
        <h2>국내 최초에서 국내 최고의 해외문자 사이트</h2>
        <p>
          런던문자는 그동안 축적된 풍부한 경험과 전문 기술을 기반으로 주식 금융, 스포츠, 엔터테인먼트, 게임, 4차산업 코인 AI 등 다양한 산업에서 기업들이 필요로 하는 플랫폼을 제공하기 위해 지속적으로
          노력하고 있습니다.
        </p>
      </div>
      <div>
        <img src="/img/bg_card_2.webp" alt="런던문자 슬라이드 이미지1" width="640" height="290">
        <h2>해외 발신번호 사용</h2>
        <p>
          런던을 비롯한 해외의 발신번호를 자동 채택하여 문자를 발송하기 때문에 복잡한 발신번호 관리가 필요 없습니다.
        </p>
      </div>
      <div>
        <img src="/img/bg_card_3.webp" alt="런던문자 슬라이드 이미지3" width="640" height="290">
        <h2>2배 길어진 문자</h2>
        <p>
          해외문자는 일반 단문 문자보다 약 2배 정도 길게 문자 작성이 가능합니다. (한글 70자, 영문 140자까지)
        </p>
      </div>
    </div>

    <div class="section has-title" id="method-section">
      <h2>해외문자 1위 업체 런던문자만의 특별한 기능</h2>
      <p>항상 퍼포먼스와 혁신을 제시하고,<br /> 언제나 최고의 국제문자 파트너가 되고자 노력합니다.</p>
      <ul>
        <li>
          <div class="img-wrap">
            <img src="/img/img_features_1.webp" alt="3사테스트 안내 이미지" />
          </div>
          <h3 class="title">3사 테스트</h3>
          <p class="desc">더 높은 도달율을 위해 실시간 3사테스트를 제공하고 있습니다.</p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="/img/img_features_2.webp" alt="API 지원 안내 이미지" />
          </div>
          <h3 class="title">API 지원</h3>
          <p class="desc">외부에서 개발 및 연동이 가능한 문자발송 API를 제공합니다. (필요 시 요청해주세요.)</p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="/img/img_features_3.webp" alt="치환/템플릿 안내 이미지" />
          </div>
          <h3 class="title">치환문자 지원</h3>
          <p class="desc">
            고객관리를 효율적으로 할 수 있는 엑셀을 이용한 치환문자를 제공합니다. (필요 시 요청해주세요.)
          </p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="/img/img_features_4.webp" alt="높은 발송률 안내 이미지" />
          </div>
          <h3 class="title">99%의 높은 발송률</h3>
          <p class="desc">
            발송률은 가장 중요한 요소 중 하나로서 다년간의 고도화된 기술을 접목해 누적 99%의 발송률을 보여주고 있습니다.
          </p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="/img/img_features_5.webp" alt="빠른 발송속도 안내 이미지" />
          </div>
          <h3 class="title">빠른 발송속도</h3>
          <p class="desc">
            병렬구조로 문자를 발송하기 때문에 발송 처리 속도가 매우 빠릅니다.
          </p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="/img/img_features_6.webp" alt="전송 실패 건 자동 환불 안내 이미지" />
          </div>
          <h3 class="title">전송 실패 건 자동 환불</h3>
          <p class="desc">
            결번 등의 이유로 전송이 실패된 경우 4시간 이내에 자동으로 환불됩니다
          </p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="/img/img_features_7.webp" alt="완벽한 보안 안내 이미지" />
          </div>
          <h3 class="title">완벽한 보안</h3>
          <p class="desc">
            엔터프라이즈급 보안을 위해 아마존 AWS를 사용하여 고객의 소중한 데이터를 보호합니다.
          </p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="/img/img_features_8.webp" alt="연중무휴 24시간 지원 안내 이미지" />
          </div>
          <h3 class="title">연중무휴 24시간 지원</h3>
          <p class="desc">
            고객의 불편사항 및 개선사항을 듣기 위해 라이브챗과 1:1문의 등의 채널이 항상 열려있습니다.
          </p>
        </li>
      </ul>
    </div>

    <div id="process-section" class="section has-title">
      <h2>런던문자의 국제문자 서비스 이용순서</h2>
      <p>모든 프로세스를 자동화로 구축하여<br />원스톱 서비스가 가능합니다.</p>
      <ul>
        <li :class="{ active: index2 == 0 }">
          <span class="icon"><img src="/img/main_con02_icon01.webp" alt="충전 신청 안내 이미지" width="47" height="47" /></span>
          <span class="dot"></span>
          <strong><em>01</em>
            <h3>충전 신청</h3>
          </strong>
          <p>충전 메뉴에서<br class="pc-only" />원하는 금액을 선택하여<br class="pc-only" />충전을 신청합니다.</p>
        </li>
        <li :class="{ active: index2 == 1 }">
          <span class="icon"><img src="/img/main_con02_icon02.webp" alt="가상 계좌 발급 안내 이미지" width="47" height="47" /></span>
          <span class="dot"></span> <strong><em>02</em>
            <h3>가상 계좌 발급</h3>
          </strong>
          <p>
            가상 계좌가 생성되며, <br class="pc-only" />
            생성된 가상계좌는 <br class="pc-only" />
            24시간 동안 유효합니다.
          </p>
        </li>
        <li :class="{ active: index2 == 2 }">
          <span class="icon"><img src="/img/main_con02_icon03.webp" alt="가상계좌 입금 안내 이미지" width="47" height="47" /></span>
          <span class="dot"></span>
          <strong><em>03</em>
            <h3>충전</h3>
          </strong>
          <p>
            가상계좌에 입금을 하면 <br class="pc-only" />
            10분안에 충전이 <br class="pc-only" />
            자동으로 처리됩니다.
          </p>
        </li>
      </ul>
      <ul>
        <li :class="{ active: index2 == 3 }">
          <span class="icon"><img src="/img/main_con02_icon04.webp" alt="3사 테스트 발송 안내 이미지" width="47"
              height="47" /></span>
          <span class="dot"></span>
          <strong><em>04</em>
            <h3>
              3사 테스트
            </h3>
          </strong>
          <p>
            테스트 메세지를 발송하면 <br class="pc-only" />
            3사 결과를 바로 보여줍니다.<br />
            (결과 업데이트 최대 1분)
          </p>
        </li>
        <li :class="{ active: index2 == 4 }">
          <span class="icon"><img src="/img/main_con02_icon05.webp" alt="SMS 발송 안내 이미지" width="47" height="47" /></span>
          <span class="dot"></span>
          <strong><em>05</em>
            <h3>
              SMS 발송
            </h3>
          </strong>
          <p>수신번호를 직접 입력하거나 <br class="pc-only" />엑셀, 메모장 파일을 업로드하여 SMS를 발송합니다.</p>
        </li>
        <li :class="{ active: index2 == 5 }">
          <span class="icon"><img src="/img/main_con02_icon06.webp" alt="전송 결과 확인 안내 이미지" width="47" height="47" /></span>
          <span class="dot"></span>
          <strong><em>06</em>
            <h3>
              결과 확인
            </h3>
          </strong>
          <p>
            발송이 잘 되었는지 <br class="pc-only" />
            상태를 확인합니다. <br />
            (결과 업데이트 최대 30분)
          </p>
        </li>
      </ul>
    </div>

    <div id="faq-section">
      <div>
        <div class="left-box">
          <h2>자주 묻는 질문</h2>
          <p>질문이 있으신가요? 저희가 답변해드리겠습니다</p>
        </div>
        <div class="right-box">
          <ul>
            <li :class="{ active: index3 == 0 }">
              <p class="question" @click="clickFaq(0)">결과는 '성공'으로 뜨는데 실제로 문자를 받지 못했나요?</p>
              <p class="answer">
                대부분 이동통신사 스팸필터링 서비스에 의해 스팸으로 분류되어 전송이 되지 않은 경우입니다. 차단된 문자를
                확인하시려면 수신자 본인이 단말기 및 이동통신사 홈페이지에서 확인해 주셔야 합니다.
              </p>
            </li>
            <li :class="{ active: index3 == 1 }">
              <p class="question" @click="clickFaq(1)">
                충전을 했는데 충전이 안되나요?
              </p>
              <p class="answer">
                충전은 10분내로 자동으로 처리됩니다. 10분이 지나도 충전이 안되면 문의 주시기 바랍니다.
              </p>
            </li>
            <li :class="{ active: index3 == 2 }">
              <p class="question" @click="clickFaq(2)">이미지는 발송할 수 없나요?</p>
              <p class="answer">이미지는 발송할 수 없습니다.</p>
            </li>
            <li :class="{ active: index3 == 3 }">
              <p class="question" @click="clickFaq(3)">발송은 한번에 몇 건까지 가능한가요?</p>
              <p class="answer">
                1회 발송량을 제한하고 있지는 않습니다. 다만 5,000~1만건씩 나눠서 보내는게 딜레이없이 빠르게 발송됩니다.
              </p>
            </li>
            <li :class="{ active: index3 == 4 }">
              <p class="question" @click="clickFaq(4)">
                발송한 지 시간이 경과했는데, 왜 계속 ＇대기＇상태로 나오나요?
              </p>
              <p class="answer">
                발송현황 게이지가 올라온 상태에서 발송을 했다면 발송요청이 밀려있어 딜레이가 발생될 수 있습니다.
              </p>
            </li>
            <li :class="{ active: index3 == 5 }">
              <p class="question" @click="clickFaq(5)">고정 수신번호가 무엇인가요?</p>
              <p class="answer">
                고정 수신번호는 문자발송시에 항상 사용하는 수신 번호가 있을 때 활성화시켜서 사용하시면 됩니다.
              </p>
            </li>
            <li :class="{ active: index3 == 6 }">
              <p class="question" @click="clickFaq(6)">갑자기 화면이 이상하게 나오거나 기능이 작동되지 않아요.</p>
              <p class="answer">
                크롬 브라우저 사용을 권장드리며, 문제발생시 컨트롤+F5 를 눌러서 캐시삭제 리프레시를 해주세요. 그래도
                문제 해결이 안되면 안되면 문의 주시기 바랍니다.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeDesktopSwiper from '@/views/components/HomeDesktopSwiper'

export default {
  name: 'Home',
  components: {
    HomeDesktopSwiper
  },
  data: () => {
    return {
      interval: null,
      interval2: null,
      index: -1,
      index2: 0,
      index3: -1,
      showText: true,
      homeSubText: '국내최초',
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      videoSrc: null
    }
  },
  methods: {
    updateVideoTime(e) {
      const currentTime = this.$refs['homeVideo'].currentTime
      let targetIndex = -1
      if (currentTime < 24) {
        this.showText = true
        targetIndex = parseInt(currentTime / 6)
      } else if (currentTime < 28) {
        this.showText = false
        targetIndex = -1
      } else if (currentTime > 27) {
        this.showText = true
      }

      if (this.index != targetIndex) {
        this.index = targetIndex
      }
    },
    handleScroll(e) {
      var isTransparent = window.scrollY < 100
      if (isTransparent) {
        document.getElementsByClassName('app-header')[0].classList.add('transparent')
      } else {
        document.getElementsByClassName('app-header')[0].classList.remove('transparent')
      }
    },
    clickFaq(faqIndex) {
      this.index3 = this.index3 != faqIndex ? faqIndex : -1
    },
  },

  mounted() {
    document.getElementsByClassName('app-header')[0].classList.add('transparent')
    document.body.classList.add('bg-white')
    document.body.classList.add('home')

    document.getElementsByClassName('main')[0].classList.remove('pt-4')
    document.getElementsByClassName('main')[0].classList.remove('pb-4')
    document.getElementsByClassName('container-fluid')[0].classList.add('full-container')
    window.addEventListener('scroll', this.handleScroll)
    var t = this


    this.videoSrc = this.isMobile ? '/assets/vod_index_m.mp4' : '/assets/vod_index.mp4'



    this.interval2 = setInterval(function () {
      t.index2++
      if (t.index2 > 5) {
        t.index2 = 0
      }
    }, 3000)
  },
  destroyed() {
    document.getElementsByClassName('app-header')[0].classList.remove('transparent')
    document.body.classList.remove('home')
    document.getElementsByClassName('main')[0].classList.add('pt-4')
    document.getElementsByClassName('main')[0].classList.add('pb-4')
    document.getElementsByClassName('container-fluid')[0].classList.remove('full-container')
    document.getElementsByClassName('app-body')[0].removeEventListener('scroll', this.handleScroll)
    clearInterval(this.interval)
    clearInterval(this.interval2)
  },
  watch: {
    index: () => { },
    showText: () => { },
  },
}
</script>
<style lang="scss">
.is-home .app-body {
  margin: 0 !important;
}

@media screen and (max-width: 800px) {

  #home-page {
    .container {
      position: relative;
      padding: 0 0 3%;
    }

    .bg {
      width: 100%;
    }

    .small-bg {
      display: block;
      margin: 0 auto;
      width: 52.27%;
    }

    #home-video-container {
      position: relative;
      width: 100%;
      padding-bottom: 120%;
      background: black;
      overflow: hidden;
    }

    #home-video-container>video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .home-text {
      position: absolute;
      top: 37.5%;
      left: 3%;
      width: 94%;
      font-size: 7vw;
      line-height: 1.4;
      font-weight: 300;
      text-align: left;
      color: white;
      letter-spacing: -1px;
      opacity: 0;

      >span {
        font-weight: 600;
        font-size: 10vw;
        margin-left: -0.2vw;
        letter-spacing: -1px;
      }
    }

    @keyframes visualTxt {
      0% {
        opacity: 0;
        transform: translateY(50px);
      }

      22% {
        opacity: 1;
        transform: translateY(0);
      }

      80% {
        opacity: 1;
        transform: translateY(0);
      }

      100% {
        opacity: 0;
        transform: translateY(-50px);
      }
    }

    .home-text.show {
      -webkit-animation-name: visualTxt;
      -webkit-animation-duration: 6000ms;
      -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
      animation-name: visualTxt;
      animation-duration: 6000ms;
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    #home-video-container .bg_line::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 32.6%;
      background: -moz-linear-gradient(left, #009bfa 0%, rgba(0, 155, 250, 0.8) 40%, rgba(0, 204, 255, 0) 100%);
      background: -webkit-linear-gradient(left, #009bfa 0%, rgba(0, 155, 250, 0.8) 40%, rgba(0, 204, 255, 0) 100%);
      background: linear-gradient(to right, #009bfa 0%, rgba(0, 155, 250, 0.8) 40%, rgba(0, 204, 255, 0) 100%);
      opacity: 0.3;
    }

    #home-img {
      opacity: 0;
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 2s ease-in-out;
    }

    #home-img.show {
      opacity: 1;
    }

    p {
      margin: 0;
      padding: 0.5% 0 1.5%;
      font-size: 1vw;
      line-height: 130%;
      color: #666;
    }

    .mo-card-section {
      padding: 16px;
      width: 100%;
      box-sizing: border-box;
      text-align: left;

      >div {
        margin-top: 20px;
      }

      img {
        width: 100%;
        height: auto;
      }

      h2 {
        margin: 10px 0 5px;
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .section {
      width: 100%;
      padding: 0 16px 50px;
      text-align: left;
      background: white;

      &::before {
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #009bfa;
        content: '';
      }

      >h2 {
        margin: 15px 0 10px;
        color: #000;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.2px;
      }

      >p {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.8;
        letter-spacing: -0.5px;
        color: #333;
      }
    }

    #method-section {
      margin-top: 60px;

      >ul {
        margin-top: 20px;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;

        >li {
          width: 100%;
          padding: 0 0 20px;
          box-sizing: border-box;

          .img-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f5f5f5;
            padding: 20px 0;

            >img {
              width: 100%;
              height: 150px;
              object-fit: contain;
            }
          }

          >.title {
            margin: 10px 0 0;
            font-size: 16px;
            line-height: 1;
            color: #333;
            font-weight: 700;
          }

          >.desc {
            margin-top: 5px;
            font-size: 14px;
            color: #666;
            line-height: 1.6;
          }
        }
      }
    }

    #process-section {
      overflow: hidden;

      >ul {
        margin: 10px 0;
        width: 100%;
        position: relative;
        overflow: hidden;

        &:first-of-type {
          margin-top: 50px;
        }

        &::before {
          position: absolute;
          display: block;
          content: '';
          top: 90px;
          margin-top: 4.5px;
          left: 50%;
          width: 100vw;
          height: 1px;
          transform: translateX(-50%);
          background-color: #ddd;
        }

        >li {
          position: relative;
          width: 33.33%;
          min-height: 320px;
          float: left;
          bottom: -6px;
          left: 0px;
          height: 63px;
          text-align: center;

          .icon {
            position: absolute;
            display: inline-block;
            width: 100%;
            text-align: center;
            top: 0;
            left: 0;

            img {
              display: inline-block;
              -webkit-transform: translateY(0px);
              -moz-transform: translateY(0px);
              -ms-transform: translateY(0px);
              -o-transform: translateY(0px);
              transform: translateY(0px);
              -webkit-transition: all 0.5s ease-out;
              -moz-transition: all 0.5s ease-out;
              -ms-transition: all 0.5s ease-out;
              -o-transition: all 0.5s ease-out;
              transition: all 0.5s ease-out;
            }
          }

          .dot {
            display: block;
            position: absolute;
            top: 90px;
            margin-top: -4.5px;
            left: 50%;
            width: 9px;
            height: 9px;
            margin-left: -4.5px;
            background-color: #ddd;
            border-radius: 50%;
            -webkit-transition: all 0.5s ease-out;
            -moz-transition: all 0.5s ease-out;
            -ms-transition: all 0.5s ease-out;
            -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;

            &:before {
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -7.5px;
              margin-left: -7.5px;
              display: block;
              content: '';
              width: 15px;
              height: 15px;
              border: 2px solid #009bfa;
              border-radius: 50%;
              opacity: 0;
              -webkit-transition: all 0.5s ease-out;
              -moz-transition: all 0.5s ease-out;
              -ms-transition: all 0.5s ease-out;
              -o-transition: all 0.5s ease-out;
              transition: all 0.5s ease-out;
            }
          }

          strong {
            position: absolute;
            display: inline-block;
            white-space: nowrap;
            line-height: 1;
            width: 100%;
            text-align: center;
            font-weight: 400;
            top: 122px;
            left: 0;
            color: #222;
            font-size: 20px;
            letter-spacing: -0.5px;

            em {
              display: block;
              margin-bottom: 20px;
              width: 100%;
              font-size: 15px;
            }
          }

          p {
            position: absolute;
            top: 200px;
            left: 0;
            width: 100%;
            bottom: 0;
            text-align: center;
            font-size: 13px;
            line-height: 1.61;
            letter-spacing: -0.2px;
            color: #666;
          }

          &.active {
            .icon img {
              -webkit-transform: translateY(-15px);
              -moz-transform: translateY(-15px);
              -ms-transform: translateY(-15px);
              -o-transform: translateY(-15px);
              transform: translateY(-15px);
            }

            .dot {
              background-color: #009bfa;

              &::before {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    #faq-section {
      padding: 50px 16px;
      width: 100%;
      background: #131313;

      >div {
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-flow: column;
        text-align: left;
        gap: 20px;

        .left-box {
          width: 100%;

          >h2 {
            padding: 0;
            font-weight: 400;
            font-size: 30px;
            color: white;
          }

          >p {
            margin-top: 20px;
            color: #9d9d9d;
            font-size: 16px;
            line-height: 1.6;
            font-weight: 400;
            color: #9d9d9d;
          }
        }

        .right-box {
          width: 100%;

          ul {
            li {
              display: block;
              border-bottom: 1px solid rgba(151, 151, 151, 0.3);

              .question {
                position: relative;
                padding: 20px 30px 20px 0;
                color: #fff;
                font-weight: 400;
                cursor: pointer;
                font-size: 16px;
                transition: color 0.3s ease-in-out;

                &::before {
                  position: absolute;
                  right: 0;
                  top: 10px;
                  display: block;
                  content: ' ';
                  width: 30px;
                  height: 30px;
                  background: url(/img/main_arr_down.svg);
                  transition: all 0.3s ease-in-out;
                  background-size: contain;
                }

                &:hover {
                  color: #009bfa;
                }
              }

              .answer {
                padding: 0;
                font-size: 16px;
                height: 0;
                opacity: 0;
                transition: all 0.2s ease-out;
              }

              &.active {
                .question {
                  &::before {
                    transform: rotate(180deg);
                  }
                }

                .answer {
                  opacity: 1;
                  padding-bottom: 25px;
                  height: auto;
                  color: #9d9d9d;
                  transform: translateY(0);
                }
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (min-width: 801px) {
  #home-page {
    .container {
      position: relative;
      padding: 0 0 3%;
    }

    .bg {
      width: 100%;
    }

    .small-bg {
      display: block;
      margin: 0 auto;
      width: 52.27%;
    }

    #home-video-container {
      position: relative;
      width: 100%;
      padding-bottom: 40%;
      overflow: hidden;
      background: black;
    }

    #home-video-container>video {
      position: absolute;
      left: 0;
      top: -10%;
      width: 100%;
    }

    .home-text {
      position: absolute;
      top: 37.5%;
      left: 3%;
      width: 94%;
      font-size: 2.7vw;
      line-height: 1.2;
      font-weight: 300;
      text-align: left;
      color: white;
      opacity: 0;
      letter-spacing: -1px;
      transition: all 0.3s ease-in-out;

      >span {
        font-weight: 600;
        font-size: 6.5vw;
        margin-left: -0.2vw;
        letter-spacing: -2px;
      }
    }

    @keyframes visualTxt {
      0% {
        opacity: 0;
        transform: translateY(50px);
      }

      22% {
        opacity: 1;
        transform: translateY(0);
      }

      80% {
        opacity: 1;
        transform: translateY(0);
      }

      100% {
        opacity: 0;
        transform: translateY(-50px);
      }
    }

    #home-video-container .bg_line::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 32.6%;
      background: -moz-linear-gradient(left, #009bfa 0%, rgba(0, 155, 250, 0.8) 40%, rgba(0, 204, 255, 0) 100%);
      background: -webkit-linear-gradient(left, #009bfa 0%, rgba(0, 155, 250, 0.8) 40%, rgba(0, 204, 255, 0) 100%);
      background: linear-gradient(to right, #009bfa 0%, rgba(0, 155, 250, 0.8) 40%, rgba(0, 204, 255, 0) 100%);
      opacity: 0.3;
    }

    .home-text.show {
      -webkit-animation-name: visualTxt;
      -webkit-animation-duration: 6000ms;
      -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
      animation-name: visualTxt;
      animation-duration: 6000ms;
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    #home-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 2s ease-in-out;
    }

    #home-img.show {
      opacity: 1;
    }

    p {
      margin: 0;
      padding: 0.5% 0 1.5%;
      font-size: 1vw;
      line-height: 130%;
      color: #999;
    }

    @media all and (max-width: 1260px) {
      .home-text {
        font-size: 41px;
        letter-spacing: -1px;
      }

      h3 {
        font-size: 30px;
      }

      p {
        font-size: 15px;
      }
    }

    #home-swiper {
      margin: 50px auto;
      width: 1024px;
      height: 510px;
      display: flex;

      .swiper-wrapper {
        width: 100%;
        height: 462px;

        .swiper-slide {
          text-align: left;
          color: white;
          padding: 60px;

          &.card1 {
            background: url(/img/bg_card_1.webp) center no-repeat;
            background-size: cover;
          }

          &.card2 {
            background: url(/img/bg_card_2.webp) center no-repeat;
            background-size: cover;
          }

          &.card3 {
            background: url(/img/bg_card_3.webp) center no-repeat;
            background-size: cover;
          }

          h2 {
            margin: 0;
            line-height: 1;
            font-size: 40px;
            color: white;
          }

          p {
            margin-top: 30px;
            font-size: 18px;
            line-height: 1.8;
            color: white;
          }
        }
      }

      .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
          width: 12px;
          height: 12px;
          border-radius: 6px;
          transition: all 0.3s;
        }

        .swiper-pagination-bullet-active {
          width: 40px;
          background: #13a3f3 !important;
        }
      }
    }

    .section {
      margin: 0 auto;
      width: 1024px;
      padding: 50px 0 0;
      text-align: left;
      background: white;

      &::before {
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #009bfa;
        content: '';
      }

      >h2 {
        margin: 24px 0 25px;
        color: #000;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.2px;
      }

      >p {
        font-size: 32px;
        font-weight: bold;
        line-height: 48px;
        letter-spacing: -0.5px;
        color: #333;
      }
    }

    #method-section {
      >ul {
        margin: 80px auto 0;
        width: 100%;
        overflow: hidden;

        >li {
          float: left;
          width: 33.33%;
          padding: 0 15px 20px;
          box-sizing: border-box;
          min-height: 390px;

          .img-wrap>img {
            width: 100%;
          }

          >.title {
            margin: 20px 0 0;
            font-size: 16px;
            line-height: 1;
            color: #333;
            font-weight: 700;
          }

          >.desc {
            margin: 10px 0 0;
            font-size: 14px;
            color: #999;
            line-height: 1.6;
          }
        }
      }
    }

    #process-section {
      padding-bottom: 100px;
      overflow: hidden;

      >ul {
        margin-top: 80px;
        width: 100%;
        position: relative;

        &::before {
          position: absolute;
          display: block;
          content: '';
          top: 90px;
          margin-top: 4.5px;
          left: 50%;
          width: 100vw;
          height: 1px;
          transform: translateX(-50%);
          background-color: #ddd;
        }

        >li {
          position: relative;
          width: 16.66%;
          min-height: 300px;
          float: left;
          bottom: -6px;
          left: 0px;
          height: 63px;
          text-align: center;

          .icon {
            position: absolute;
            display: inline-block;
            width: 100%;
            text-align: center;
            top: 0;
            left: 0;

            img {
              display: inline-block;
              -webkit-transform: translateY(0px);
              -moz-transform: translateY(0px);
              -ms-transform: translateY(0px);
              -o-transform: translateY(0px);
              transform: translateY(0px);
              -webkit-transition: all 0.5s ease-out;
              -moz-transition: all 0.5s ease-out;
              -ms-transition: all 0.5s ease-out;
              -o-transition: all 0.5s ease-out;
              transition: all 0.5s ease-out;
            }
          }

          .dot {
            display: block;
            position: absolute;
            top: 90px;
            margin-top: -4.5px;
            left: 50%;
            width: 9px;
            height: 9px;
            margin-left: -4.5px;
            background-color: #ddd;
            border-radius: 50%;
            -webkit-transition: all 0.5s ease-out;
            -moz-transition: all 0.5s ease-out;
            -ms-transition: all 0.5s ease-out;
            -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;

            &:before {
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -7.5px;
              margin-left: -7.5px;
              display: block;
              content: '';
              width: 15px;
              height: 15px;
              border: 2px solid #009bfa;
              border-radius: 50%;
              opacity: 0;
              -webkit-transition: all 0.5s ease-out;
              -moz-transition: all 0.5s ease-out;
              -ms-transition: all 0.5s ease-out;
              -o-transition: all 0.5s ease-out;
              transition: all 0.5s ease-out;
            }
          }

          strong {
            position: absolute;
            display: inline-block;
            width: 100%;
            text-align: center;
            font-weight: 400;
            top: 122px;
            left: 0;
            color: #222;
            font-size: 24px;
            letter-spacing: -0.5px;

            em {
              display: block;
              margin-bottom: 20px;
              width: 100%;
              font-size: 15px;
            }
          }

          p {
            position: absolute;
            top: 220px;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 13px;
            line-height: 1.61;
            letter-spacing: -0.2px;
            color: #999;
          }

          &.active {
            .icon img {
              -webkit-transform: translateY(-15px);
              -moz-transform: translateY(-15px);
              -ms-transform: translateY(-15px);
              -o-transform: translateY(-15px);
              transform: translateY(-15px);
            }

            .dot {
              background-color: #009bfa;

              &::before {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    #faq-section {
      padding: 100px 10%;
      width: 100%;
      background: #131313;

      >div {
        margin: 0 auto;
        width: 1024px;
        display: flex;
        flex-flow: row;
        text-align: left;

        .left-box {
          width: 100%;

          >h2 {
            font-weight: 400;
            font-size: 45px;
            color: white;
          }

          >p {
            margin-top: 20px;
            color: #9d9d9d;
            font-size: 16px;
            line-height: 1.6;
            font-weight: 400;
            color: #9d9d9d;
          }
        }

        .right-box {
          width: 100%;

          ul {
            li {
              display: block;
              border-bottom: 1px solid rgba(151, 151, 151, 0.3);

              .question {
                position: relative;
                padding: 25px 50px 25px 0;
                color: #fff;
                font-weight: 400;
                cursor: pointer;
                font-size: 16px;
                transition: color 0.3s ease-in-out;

                &::before {
                  position: absolute;
                  right: 0;
                  top: 25px;
                  display: block;
                  content: ' ';
                  width: 30px;
                  height: 30px;
                  background: url(/img/main_arr_down.svg);
                  transition: all 0.3s ease-in-out;
                  background-size: contain;
                }

                &:hover {
                  color: #009bfa;
                }
              }

              .answer {
                padding: 0;
                font-size: 16px;
                height: 0;
                opacity: 0;
                transition: all 0.2s ease-out;
              }

              &.active {
                .question {
                  &::before {
                    transform: rotate(180deg);
                  }
                }

                .answer {
                  opacity: 1;
                  padding-bottom: 25px;
                  height: auto;
                  color: #9d9d9d;
                  transform: translateY(0);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
